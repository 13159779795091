const LinePromotionModalStyle = {
  content: {
    top: '50%',
    left      : '50%',
    transform : 'translate(-50%, -50%)',
    width     : '830px',
    maxHeight : '640px',
  },
  overlay: {
    zIndex: '150',
    backgroundColor: "rgba(19, 165, 112, 0.8)"
  }
};
export { LinePromotionModalStyle as default };
