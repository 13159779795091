import ReactModal from "react-modal";
import LinePromotionModalStyle from "./line_promotion_modal_style";
import React from "react";
import {Component} from "react";

class LinePromotionModal extends Component {

  render () {
    return <ReactModal isOpen={this.props.showModal} onRequestClose={this.props.handleCloseModal} style={LinePromotionModalStyle} ariaHideApp={false}>
      <div className={'line__modal__container'}>
        <div className={'text_container'}>
          <p>★トラッカーズ【公式】LINE＠アカウント★</p>
          <p>トラッカーズのお買い得な中古トラックの新着在庫の情報や、<br/>価格情報をお届けいたします♪</p>
          <p>友達追加は、QRコードを読み取るか、<br/>【 @trc01 】をLINEの友達に追加してください。</p>
        </div>
        <div className={'qr_image_container'}>
          <img src={'/img/line_qrcode.png'}/>
        </div>
      </div>
    </ReactModal>
  }
}

export default LinePromotionModal
