import React from "react"
import { render } from 'react-dom'
import LinePromotion from "../src/components/site/line_promotion"

if (document.getElementById('root_line_promotion_container') != null) {
  render(
    <LinePromotion/>,
    document.getElementById('root_line_promotion_container')
  )
}
