import React, {Component, Fragment} from 'react'
import MediaQuery from 'react-responsive'
import LinePromotionModal from "./line_promotion_modal";
import LineIcon from 'images/line_icon.png'

class LinePromotion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleClick() {
    this.setState({showModal: true});
  }

  handleCloseModal() {
    this.setState({showModal: false});
  }

  render() {
    return <Fragment>
      <MediaQuery query="(min-device-width: 1224px)">
        <p onClick={this.handleClick} className={"l-footer-Gnav__button line__modal__opener"}><span>
          <img src={LineIcon} width={59}/>
          トラッカーズ公式LINE@
        </span></p>
        <LinePromotionModal showModal={this.state.showModal} handleCloseModal={this.handleCloseModal}/>
      </MediaQuery>
    </Fragment>
  }
}

export default LinePromotion
